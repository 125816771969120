import React from "react";
import Paragraph from "../../components/paragraph";
import SEO from "../../components/seo";
import WorkDetail from "../../components/work-detail";
import WorkDetailImage from "../../components/work-detail-image";
import WorkDetailIntro from "../../components/work-detail-intro";
import WorkDetailText from "../../components/work-detail-text";

import introImageSm from "../../images/work/office/intro-sm.jpg";
import introImageMd from "../../images/work/office/intro-md.jpg";
import introImageLg from "../../images/work/office/intro-lg.jpg";
import contentImage1Sm from "../../images/work/office/content-1-sm.jpg";
import contentImage1Md from "../../images/work/office/content-1-md.jpg";
import contentImage1Lg from "../../images/work/office/content-1-lg.jpg";
import contentImage2Sm from "../../images/work/office/content-2-sm.jpg";
import contentImage2Md from "../../images/work/office/content-2-md.jpg";
import contentImage2Lg from "../../images/work/office/content-2-lg.jpg";
import contentImage3Sm from "../../images/work/office/content-3-sm.jpg";
import contentImage3Md from "../../images/work/office/content-3-md.jpg";
import contentImage3Lg from "../../images/work/office/content-3-lg.jpg";
import contentImage4Sm from "../../images/work/office/content-4-sm.jpg";
import contentImage4Md from "../../images/work/office/content-4-md.jpg";
import contentImage4Lg from "../../images/work/office/content-4-lg.jpg";
import contentImage5Sm from "../../images/work/office/content-5-sm.jpg";
import contentImage5Md from "../../images/work/office/content-5-md.jpg";
import contentImage5Lg from "../../images/work/office/content-5-lg.jpg";
import contentImage6Sm from "../../images/work/office/content-6-sm.jpg";
import contentImage6Md from "../../images/work/office/content-6-md.jpg";
import contentImage6Lg from "../../images/work/office/content-6-lg.jpg";
import contentImage7Sm from "../../images/work/office/content-7-sm.jpg";
import contentImage7Md from "../../images/work/office/content-7-md.jpg";
import contentImage7Lg from "../../images/work/office/content-7-lg.jpg";
import contentImage8Sm from "../../images/work/office/content-8-sm.jpg";
import contentImage8Md from "../../images/work/office/content-8-md.jpg";
import contentImage8Lg from "../../images/work/office/content-8-lg.jpg";

const OfficePage = () => (
  <>
    <SEO title="Denver Office Graphics" />
    <WorkDetail title="Denver Office Graphics">
      <WorkDetailIntro
        title="Wall Designs for a Collaborative and Contemporary Workspace"
        description={
          <Paragraph variant="work-detail">
            Satellite TV provider and owner of Sling TV, DISH Network, obtained
            a new office space located in downtown Denver. I designed graphics
            to brighten up the plain white walls that represented both DISH and
            Sling branding. The result was a fun yet professional space for
            marketing and creative teams to work.
          </Paragraph>
        }
        images={{
          sm: introImageSm,
          md: introImageMd,
          lg: introImageLg,
          alt: "Abstract, geometric mountain scene.",
        }}
        role="Design"
        client="DISH Network"
        project="office"
      />
      <WorkDetailText heading="Transforming White Walls to an Abstract Landscape">
        <Paragraph variant="work-detail">
          I was involved in the process from the beginning to the end of the
          project. This included brainstorming concepts with other designers,
          creating mood boards, multiple rounds of design reviews, finalizing my
          designs for the vendor to print, and helping to select paint colors
          for the walls.
        </Paragraph>
        <Paragraph variant="work-detail">
          I designed graphics for three of the walls throughout the building,
          consisting of two in the first floor office space, and one for a
          meeting area on the second floor.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage1Sm,
          md: contentImage1Md,
          lg: contentImage1Lg,
          alt: "Left side of the geometic mountain design.",
        }}
        caption="Left side of the wall"
      />
      <WorkDetailImage
        images={{
          sm: contentImage2Sm,
          md: contentImage2Md,
          lg: contentImage2Lg,
          alt: "Right side of the geometic mountain design.",
        }}
        caption="Right side of the wall"
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          For a long wall on the first floor, I designed an abstract mountain
          scene, fitting for the Colorado location. Covering the entire wall
          with vinyl was too expensive, so the design starts at desk height, and
          the rest of the wall was painted.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage3Sm,
          md: contentImage3Md,
          lg: contentImage3Lg,
          alt: "Finished geometric graphic on the wall in the office space.",
        }}
        caption="Final design on the wall"
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          The opposing wall continues with the abstract nature theme, but is a
          more subdued design so that the space didn’t feel overwhelming.
        </Paragraph>
        <Paragraph variant="work-detail">
          Keeping with the color scheme, I represented the DISH and Sling brand
          colors in a sunburst design, giving a creative and playful feel to the
          space.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage4Sm,
          md: contentImage4Md,
          lg: contentImage4Lg,
          alt: "Abstract sunburst design.",
        }}
      />
      <WorkDetailImage
        images={{
          sm: contentImage5Sm,
          md: contentImage5Md,
          lg: contentImage5Lg,
          alt:
            "Final abstract sunburst design on the wall in the office space.",
        }}
        caption="Final design on the wall, vinyl on paint"
      />
      <WorkDetailText heading="From the Outside In">
        <Paragraph variant="work-detail">
          On the second floor a collaborative work area was in need of some bold
          color to give more energy to the office. Similar to how the first
          floor brings inspiration from the outdoors, this wall design
          intentionally intersects the windows to draw attention to the bustling
          city right outside the walls.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        imagesLeft={{
          sm: contentImage6Sm,
          md: contentImage6Md,
          lg: contentImage6Lg,
          alt: "Overlapping diamonds design.",
        }}
        imagesRight={{
          sm: contentImage7Sm,
          md: contentImage7Md,
          lg: contentImage7Lg,
          alt: "Overlapping diamonds design.",
        }}
      />
      <WorkDetailImage
        images={{
          sm: contentImage8Sm,
          md: contentImage8Md,
          lg: contentImage8Lg,
        }}
        alt="Overlapping diamonds design on the wall in the office space."
      />
    </WorkDetail>
  </>
);

export default OfficePage;
